<template>
  <validation-observer ref="simpleRules">
    <b-row>
      <b-col>
        <div id="component-breadcrumbs">
          <b-breadcrumb
            class="breadcrumb-slash"
            :items="breadcrumbItems"
          />
        </div>
      </b-col>
      <b-col v-if="readOnly">
        <div class="float-right mr-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="editScreen()"
          >
            Edit
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-form class="p-2">
      <b-row>
        <b-col
          md="12"
          class="pr-6"
        >
          <b-row class="pb-2 pt-2">
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Client Name"
                rules="required"
              >
                <b-form-group
                  label="Insured Name"
                  label-for="clientname"
                >
                  <b-form-input
                    id="clientname"
                    v-model="clientObject.Name"
                    placeholder=""
                    name="clientname"
                    :state="errors.length > 0 ? false:null"
                    :readonly="readOnly"
                    @keypress.enter.prevent
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Deal Owner"
                label-for="dealOwner"
              >
                <v-select
                  id="dealOwner"
                  v-model="DealOwnerUserID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="dealOwner"
                  placeholder=""
                  name="dealOwner"
                  :disabled="readOnly"
                  class="deal-owner"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Status"
                label-for="status"
              >
                <v-select
                  id="status"
                  v-model="clientObject.CRMStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="option"
                  placeholder=""
                  name="status"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-if="!readOnly"
            class="pb-2 pt-2"
          >
            <b-col>
              <div class="d-flex justify-content-between">
                <div>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                    @click="resetForm()"
                  >
                    Cancel
                  </b-button>
                </div>
                <div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    :disabled="isLoading"
                    @click.prevent="validationForm()"
                  >
                    Save
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {BBreadcrumb, BButton, BCol, BForm, BFormGroup, BFormInput, BRow,} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import "vue-good-table/dist/vue-good-table.css";
import vSelect from 'vue-select'
import {required} from '@validations'
import store from "@/store";
import APIService from "@core/utils/APIService";

const apiService = new APIService();

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    BBreadcrumb,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      readOnly: false,
      clientObject: {
        CRMStatus: {title: 'Active', value: 'Active'}
      },
      option: [{ title: 'Active', value: 'Active' }, { title: 'Prospect', value: 'Prospect' }, { title: 'Inactive', value: 'Inactive' }],
      dealOwner: [],
      DealOwnerUserID: null,
      isLoading: false,
      required,
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Insured',
          href: '/insured'
        },
        {
          text: this.clientObject.ClientID ? this.clientObject.Name : 'Create New'
        }
      ]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      const response = await apiService.get('users/user').then(res => res.data).catch(error => error)
      this.dealOwner = response.Users.map(d => ({title: d.LastName + ", " + d.FirstName, value: d.UserAccountID}));
      if (this.dealOwner) {
        var existingClientId = this.$route.params.insuredID;
        var readOnly = this.$route.meta.view;
        this.readOnly = readOnly || false;
        if (existingClientId) {
          this.getClientData(existingClientId)
        }
      }
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    resetForm() {
      this.clientObject = {};
      this.$router.push('/insured');
    },
    processForm() {
      this.isLoading = true;
      let postData = {};
      postData.CRMStatus = (this.clientObject.CRMStatus && this.clientObject.CRMStatus.value) || null;
      postData.DealOwnerUserID = (this.DealOwnerUserID && this.DealOwnerUserID.value) || (this.DealOwnerUserID && this.DealOwnerUserID.length && this.DealOwnerUserID[0].value) || 0;
      postData.Name = this.clientObject.Name;
      postData.ClientType = 'INSURED'
      postData.ClientID = this.clientObject.ClientID;

      var endpoint = 'insured/create';
      if (postData.ClientID) {
        endpoint = 'insured/' + postData.ClientID;
      }

      apiService.post(endpoint, postData).then(res => {
        if (res) {
          this.showToast('success', 'top-center', 4000, 'Insured saved!');
          if (res.data && res.data.ClientID) {
            this.getClientData(res.data.ClientID)
          }
        } else {
            this.isLoading = false;
        }
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.processForm()
        }
      })
    },
    editScreen() {
      this.readOnly = false;
    },
    getClientData(ClientID) {
      if (ClientID) {
        apiService
            .get("insured/" + ClientID + "/detail")
            .then((response) => {
              this.clientObject = response.data;
              this.DealOwnerUserID = this.dealOwner.filter(i => i.value === response.data.DealOwnerUserID) || null;
            });
      }
    }
  },
}
</script>

<style>
hr{
  border: 1px solid #ebe9f1;
}
.large-text label {
  font-size: 1.1rem;
}
div#dealOwner {
  font-size: 0.8rem;
}
.vs__selected-options {
  padding: 0 2px;
  min-height: 2rem;
}
</style>
